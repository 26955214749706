<template>
    <div>
        <zw-select-group v-model="inputVal"
                         :options="getCustomerStatuses()"
                         name="new_value"
                         :label-prefix="labelPrefix"
        ></zw-select-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
        getCustomerStatuses() {
            const customerStatuses = this.getEntityStatuses()['customer'] || []
            let list = {}
            customerStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        },
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>